import history from '../../helpers/history';
import { showModal } from '../../redux/error/ErrorAction';
import { GET, POST } from '../../services/rest.service';
import { hideLoading } from '../loader/LoaderActions';
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const USER = 'USER';
export const UPDATE_USER = 'UPDATE_USER';

/* LOGIN */
export const login = (payload) => {
  return (dispatch) => {
    POST('/v2/brandAdmin/auth/login', payload)
      .then(({ data }) => {
        localStorage.setItem('vB_TKN', data.token);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            isLoggedIn: true,
            user: data,
            message: 'logged in successfully',
          },
        });
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            isLoggedIn: false,
            user: {},
            type: undefined,
            message: err.message,
          },
        });
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };
};

export const getLoggedInUser = () => {
  return (dispatch) => {
    const url = `/v2/brandAdmin/auth/me`;
    GET(url)
      .then(({ data }) => {
        dispatch({
          type: USER,
          payload: {
            user: data,
            type: 'ADMIN',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            user: {},
            type: undefined,
            isLoggedIn: false,
            message: err.message,
          },
        });
        localStorage.removeItem('vB_TKN');

        history.push('/');
      });
  };
};
