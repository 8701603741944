export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL= 'HIDE_MODAL';

export const showModal = (message) => {
  return {
    type: SHOW_MODAL,
    payload: {message, isshow: true},
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
    payload: {message: '', isshow: false},
  };
};
