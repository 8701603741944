import React from "react";
import classnames from "classnames";

const InputFeedback = ({ error }) =>
  error ? <div className="invalid-feedback">{error}</div> : null;

const Label = ({ error, className, children, ...props }) =>
  children ? (
    <label className={className} {...props}>
      {children}
    </label>
  ) : null;

const TextInput = ({
  type,
  id,
  size,
  label,
  error,
  value,
  onChange,
  className,
  labelClasses,
  ...props
}) => {
  const InputClasses = classnames(
    "form-control",
    {
      "is-invalid": !!error
    },
    className
  );
  const FormGroup = classnames("form-group", size);
  const LabelClasses = classnames("form-label", labelClasses);
  return (
    <div className={FormGroup}>
      <Label className={LabelClasses} htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        id={id}
        className={InputClasses}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
      />
      <InputFeedback error={error} />
    </div>
  );
};

export default TextInput;
