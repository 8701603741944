import { Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
// Services
import { Redirect } from "react-router-dom";
import { object, string } from "yup";
// Images
import TextInput from "../../components/UI/TextInput";
import { showLoading } from "../../redux/loader/LoaderActions";
import { login } from "../../redux/user/UserActions";

const LoginForm = (props) => (
  <Formik
    initialValues={{ email: "", password: "" }}
    onSubmit={(values) => {
      props.onSubmit(values);
    }}
    validationSchema={object().shape({
      email: string()
        .email("Please enter valid email")
        .required("Email is required"),
      password: string()
        .min(4, "Password length must ne greater than 4")
        .required("Password is required"),
    })}
  >
    {(renderProps) => {
      const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      } = renderProps;
      return (
        <form onSubmit={handleSubmit}>
          <TextInput
            label={"Email"}
            id="email"
            type="email"
            name={"email"}
            size="mb-4"
            className="form-control"
            placeholder="Enter your email"
            error={touched.email && errors.email}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextInput
            id="password"
            type="password"
            name={"password"}
            size="mb-4"
            label={"Password"}
            className="form-control"
            placeholder="Enter your password"
            error={touched.password && errors.password}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="form-group pt-2">
            <button className="btn btn-primary text-white font-weight-bold btn-block">
              {" "}
              Login
            </button>
          </div>
        </form>
      );
    }}
  </Formik>
);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLoginSubmit = (formData) => {
    const { dispatch } = this.props;
    dispatch(showLoading());

    dispatch(login(formData));
  };

  render() {
    if (
      localStorage.getItem("vB_TKN") &&
      localStorage.getItem("vB_TKN") !== undefined
    ) {
      return <Redirect from={`/`} to={`/brand`} />;
    }

    return (
      <div className="main-wrapper auth-wrapper d-flex justify-content-center align-items-center">
        <div className="auth-content animated fadeIn">
          <div className="card shadow mb-0">
            <div className="card-body">
              <div className="auth-header text-center">
                {/* <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 64 64"
                  width="64"
                  height="64"
                >
                  <g id="Group_2" transform="translate(-141 -7)">
                    <g>
                      <path
                        d="M152.3,68.7V7.8h13.5v54.1h6.8c1.9,0,3.5-0.7,4.8-2s2-2.9,2-4.7V7.8H193v37.8c0,6.3-2.3,11.7-6.8,16.3
			c-4.5,4.6-10,6.8-16.3,6.8H152.3z"
                      ></path>
                    </g>
                  </g>
                </svg> */}
                Veegs
              </div>
              <p className="font-weight-bold text-center mt-2 font-18 font-p22">
                If you're logging in here, we appreciate you.
              </p>

              <LoginForm onSubmit={this.handleLoginSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps, null)(Login);
