import React, { Component } from 'react';
import { withGetScreen } from 'react-getscreen';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer, cssTransition, toast } from 'react-toastify';
import ErrorModal from './app/components/ErrorModal';
import AsyncComponent from './app/components/hoc/AsyncComponent';
import AppLoader from './app/components/loaders/AppLoader';
import LoginComponent from './app/containers/login/Login';
import history from './app/helpers/history';
import { hideModal } from './app/redux/error/ErrorAction';

const AdminLayoutAsync = AsyncComponent(() => import('./app/containers/admin/layout/Layout'));

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (localStorage.getItem('vB_TKN') ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} />
);
const slide = cssTransition({
  enter: 'fadeIn',
  exit: 'fadeOut',
});
class AppRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleToggle = () => {
    const { dispatch } = this.props;

    dispatch(hideModal());
  };

  render() {
    return (
      <div className={'layout'}>
        {this.props.loader.isLoading && <AppLoader title={this.props.loader.title} />}
        {this.props.modal.isshow && <ErrorModal onToggle={this.handleToggle} {...this.props.modal} />}

        <Router history={history}>
          <Switch>
            <Route path={'/'} exact component={LoginComponent} />
            <PrivateRoute path={'/brand'} component={AdminLayoutAsync} />
          </Switch>
        </Router>
        <ToastContainer transition={slide} autoClose={4000} position={toast.POSITION.BOTTOM_CENTER} closeOnClick={true} hideProgressBar={true} />
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    loader: state.loading,
    modal: state.modal,
  };
};

export default connect(MapStateToProps, null)(withGetScreen(AppRoutes));
