import axios from 'axios';
import config from '../../config';
import history from '../helpers/history';
import store from '../redux/store';
import { LOGOUT } from '../redux/user/UserActions';

axios.defaults.baseURL = config.api.URL;

/**
 * Global request function for handling all HTTP calls
 * @param method
 * @param url
 * @param options {params: <for query params>, payload: <for data to be sent>'}
 */

const request = (method, url, options = { params: {}, payload: {}, requestOptions: {} }) => {
  const request = {
    url,
    method,
    params: options.params,
    data: options.payload,
    ...options.requestOptions,
  };

  return new Promise((resolve, reject) => {
    axios
      .request(request)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const POST = (path, payload, requestOptions) => {
  return request('POST', path, { payload, requestOptions });
};

export const GET = (path, params) => {
  return request('GET', path, { params });
};

export const GETALL = (path, filters) => {
  return request('GET', path, { params: filters });
};

export const PUT = (path, payload) => {
  return request('PUT', path, { payload: payload });
};

export const PATCH = (path, payload) => {
  return request('PATCH', path, { payload: payload });
};

export const DELETE = (path) => {
  return request('DELETE', path);
};

axios.interceptors.request.use((request) => {
  // Setting up headers
  const token = localStorage.getItem('vB_TKN');
  let headers = {};

  headers['x-client-id'] = config.CLIENT_ID;
  headers['x-client-secret'] = config.CLIENT_SECRET;
  headers['x-client-device'] = config.CLIENT_DEVICE;

  if (token) {
    headers.authorization = token;
  }
  if (request && request.privatekey) {
    headers['x-privateKey'] = 'WR5patYNF9zaOlyeRnsQta0wXVzsVWhJsnAx9yU3XGOsvAdiQG4Sf8ejcBQTSCcy';
  }

  request.headers = headers;
  return request;
});

/**
 * RESPONSE INTERCEPTOR
 */
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('vB_TKN');

      setTimeout(() => {
        store.dispatch({ type: LOGOUT });
        history.push('/');
      }, 100);
    }
    return Promise.reject(error.response.data);
  },
);
