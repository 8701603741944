import { combineReducers } from "redux";

import LoaderReducer from "./loader/LoaderReducer";
import UserReducer from "./user/UserReducer";
import ErrorReducer from "./error/ErrorReducer";

const rootReducer = combineReducers({
  loading: LoaderReducer,
  user: UserReducer,
  modal: ErrorReducer
});

export default rootReducer;
